import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormArray, FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BackboneComponent } from '@components/backbone/backbone.component';
import { BreadcrumbComponent } from '@components/breadcrumb/breadcrumb.component';
import { PreviewUploadImageComponent } from '@components/preview-upload-image/preview-upload-image.component';
import { GalleryService } from '@services/gallery.service';
import { ToastService } from '@utils/toast.service';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { requiredFileSize, requiredFileType } from '@utils/validators';

@Component({
  selector: 'app-gallery-create',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    BackboneComponent,
    BreadcrumbComponent,
    ReactiveFormsModule,
    ButtonModule,
    PreviewUploadImageComponent,
    InputTextModule
  ],
  templateUrl: './create.component.html',
  styleUrl: './create.component.scss'
})
export class GalleryCreatePage {
  private formBuilder = inject(FormBuilder);
  private galleryService = inject(GalleryService);
  private toastService = inject(ToastService);
  private router = inject(Router);

  public isLoading = false;
  public isSaving = false;
  public maxSizesUpload = 2000000;

  public form = this.formBuilder.group({
    name: [null, [Validators.required]],
    description: [null],
    images: this.formBuilder.array([])
  });

  get name() { return this.form.get('name') }
  set name(value: any) { this.name.setValue(value) }
  get description() { return this.form.get('description') }
  set description(value: any) { this.description.setValue(value) }

  get images(): FormArray { return this.form.controls["images"] as FormArray; }
  getImageAsIndex = (index: number) => this.images.controls[index].get('file')

  pushImage = () => this.images.push(this.formBuilder.group({ file: [null, [requiredFileType(['png', 'jpg', 'jpeg']), requiredFileSize(this.maxSizesUpload)]] }))
  removeImage = (index: number) => {
    this.images.removeAt(index);

    if (this.images.value <= 0) {
      this.pushImage()
    }
  }

  ngOnInit() {
    this.pushImage()
  }

  onInputFileChanged = (event: any, index: number) => {
    if (event.target.files && event.target.files.length) {
      this.images.controls[index].get('file')?.setValue(event.target.files[0]);
    }
  }

  save = async () => {
    this.isSaving = true;

    const myFormData = new FormData();

    myFormData.append('name', this.name.value)
    myFormData.append('description', this.description.value)

    for (const [index, item] of this.images.value.entries()) {
      myFormData.append(`image_${index}`, item.file)
    }

    await this.galleryService.create(myFormData)
      .then(() => {
        this.toastService.success('Galería creada correctamente.')
        this.router.navigate(['/gallery'])
      })
      .catch(() => this.toastService.error('Error al crear la galería.'))

    this.isSaving = false;
  }
}
