<backbone>
  <section header-page="topbar" class="d-flex h-100 position-relative">
    <div class="w-50 d-flex justify-content-start align-items-center">
      <p class="m-0 fw-medium">Entrada al blog: {{this.blog?.title}}</p>
    </div>

    <div class="w-50 d-flex justify-content-end align-items-center gap-3">
      <breadcrumb [links]="[{title: 'Dashboard'}, {title: 'Blog', link: '/blog'}, {title: this.blog?.title}]" />

      @if (this.blog !== null) {
      <p-button styleClass="rounded-2" size="small" icon="pi pi-save" label="Guardar" [loading]="isSaving"
        (onClick)="save()" [disabled]="!formBlog.valid"></p-button>

      <p-button *showForPermissions="['delete_blog']" styleClass="rounded-2" size="small" icon="pi pi-trash"
        severity="danger" [loading]="isDeleting" (onClick)="delete($event)"></p-button>
      }
    </div>
  </section>

  <section wrapper>
    <form [formGroup]="formBlog"
      [ngClass]="{'opacity-50 pointer-events user-select-none': this.isSaving === true || this.isDeleting === true}">
      <div class="row">
        <div class="col-md-8">
          <div class="card page-section rounded-2 shadow-sm">
            <div class="card-header align-items-center d-flex rounded-top-2">
              <h6 class="card-title flex-grow-1 m-0">Información de la cuenta de usuario.</h6>
            </div>
            <div class="card-body">
              <div class="mb-3 flex flex-column gap-2">
                <input placeholder="Título para la entrada" formControlName="title" type="text" pInputText
                  class="w-100 h-50px" />
                <small class="ps-2">Título para la entrada de tu blog. <i class="text-muted">Ejem: Mi primer blog.</i>
                  <span class="text-danger ms-2">Requerido *</span></small>
              </div>
              <div class="mb-3 flex flex-column gap-2">
                <input placeholder="Descripción" formControlName="description" type="text" pInputText
                  class="w-100 h-50px" />
                <small class="ps-2">Descripción corta, para previsualizar la entrada. <span
                    class="text-danger ms-2">Requerido *</span></small>
              </div>
              <div class="flex flex-column gap-2">
                <p-chips placeholder="Tags" formControlName="tags" styleClass="d-block" inputStyleClass="d-block w-100"
                  [separator]="separatorExp"></p-chips>
                <small class="ps-2">Usa tags para etiquetar el contenido de tu entrada. <span
                    class="text-danger ms-2">Requerido *</span></small>
              </div>
            </div>
          </div>
          <div class="card page-section rounded-2 shadow-sm">
            <div class="card-header align-items-center d-flex rounded-top-2">
              <h6 class="card-title flex-grow-1 m-0">Contenido del blog. <small class="text-danger ms-2">Requerido
                  *</small></h6>
            </div>
            <div class="card-body p-0">
              <editor formControlName="content" [init]="tinymceInit" />
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card page-section rounded-2 shadow-sm">
            <div class="card-header align-items-center d-flex rounded-top-2">
              <h6 class="card-title flex-grow-1 m-0">Imágen de portada.</h6>
            </div>
            <div class="card-body">
              <div class="d-flex flex-column gap-2">
                <preview-upload-image [image]="hero.value"
                  [imageSrc]="(this.blog?.hero?.name !== null && this.blog?.hero?.name !== '') ? this.blog?.hero?.uri : './assets/images/media-placeholder.png'"
                  [ngClass]="{'border border-danger border-4 rounded-1': hero.value?.size > this.maxSizesUpload}">
                  <input #input (change)="onHeroChanged($event)" class="form-control h-50px" type="file"
                    style="line-height: 2.6;" accept="image/*">
                </preview-upload-image>
                @if (hero.value?.size > this.maxSizesUpload) {
                <small class="px-2 text-danger">El tamaño de la imagen no debe ser mayor a 2mb.</small>
                }
                <small class="ps-2 d-block">Formatos JPG, JPEG y PNG. Tamaño máximo 2mb. <span
                    class="text-danger">Requerido *</span></small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </section>
</backbone>