import { Component } from '@angular/core';
import { BackboneComponent } from '@components/backbone/backbone.component';
import { BreadcrumbComponent } from '@components/breadcrumb/breadcrumb.component';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    BackboneComponent,
    BreadcrumbComponent
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomePage {
}
