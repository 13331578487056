import { Routes } from '@angular/router';
import { authGuard } from '@guards/auth.guard';
import { hasRolePermissionsGuard } from '@guards/has-role-permissions.guard';
import { GalleryListPage } from './list/list.component';
import { GalleryCreatePage } from './create/create.component';
import { GalleryUpdatePage } from './update/update.component';

let moduleName = 'gallery';

export const routes: Routes = [
  {
    path: moduleName,
    component: GalleryListPage,
    title: 'Lista de galerías',
    canActivate: [authGuard, hasRolePermissionsGuard(null, ['read_galleries'])],
  },
  {
    path: `${moduleName}/create`,
    component: GalleryCreatePage,
    title: 'Crear galería',
    canActivate: [authGuard, hasRolePermissionsGuard(null, ['create_galleries'])],
  },
  {
    path: `${moduleName}/details/:id`,
    component: GalleryUpdatePage,
    title: 'Actualizar galería',
    canActivate: [authGuard, hasRolePermissionsGuard(null, ['read_galleries', 'update_galleries'])],
  },
];
