import { Component, Input } from '@angular/core';

export interface IBreadcrumb {
  title: string,
  link?: string
}

@Component({
  selector: 'breadcrumb',
  standalone: true,
  imports: [],
  templateUrl: './breadcrumb.component.html',
  styleUrl: './breadcrumb.component.scss'
})
export class BreadcrumbComponent {
  @Input() links: IBreadcrumb[] = [];
}
