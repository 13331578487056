import { CommonModule, CurrencyPipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BackboneComponent } from '@components/backbone/backbone.component';
import { BreadcrumbComponent } from '@components/breadcrumb/breadcrumb.component';
import { BlogService } from '@services/blog.service';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { Table, TableModule } from 'primeng/table';
import { TagModule } from 'primeng/tag';
import { ChipModule } from 'primeng/chip';
import { ShowForPermissionsDirective } from '@directives/show-for-permissions.directive';

@Component({
  selector: 'app-blog-list',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    BackboneComponent,
    BreadcrumbComponent,
    TableModule,
    CurrencyPipe,
    TagModule,
    InputTextModule,
    ButtonModule,
    RouterModule,
    ChipModule,
    ShowForPermissionsDirective
  ],
  templateUrl: './list.component.html',
  styleUrl: './list.component.scss'
})
export class BlogListPage {
  private blogService = inject(BlogService);

  public searchValue: string | undefined;
  public blogList: any[] = [];

  async ngOnInit() {
    await this.blogService.getList().then((response) => this.blogList = response.content)
  }

  clear(table: Table) {
    table.clear();
    this.searchValue = ''
  }
}
