import { Injectable, inject } from '@angular/core';
import { HttpService } from '@utils/http.service';

@Injectable({
  providedIn: 'root'
})
export class RealEstateService {
  private http = inject(HttpService)

  public getTypes = async () => await this.http.get(`/v1/realestate/type`, false)
    .then((response: any) => response.data.content)
    .catch((error: any) => error)

  public getStatus = async () => await this.http.get(`/v1/realestate/status`, false)
    .then((response: any) => response.data.content)
    .catch((error: any) => error)

  public getProperties = async () => await this.http.get(`/v1/realestate`, false)
    .then((response: any) => response.data)
    .catch((error: any) => error)

  public getPropertyDetails = async (slug: string) => await this.http.get(`/v1/realestate/${slug}?by=slug`, false)
    .then((response: any) => response.data.content)
    .catch((error: any) => error)

  public create = async (body: any) => await this.http.post(`/v1/realestate`, body)
    .then((response: any) => response)

  public update = async (value: any, body: any) => await this.http.put(`/v1/realestate/${value}?by=slug`, body)
    .then((response: any) => response)

  public delete = async (id: string) => await this.http.delete(`/v1/realestate/${id}?by=foil`)
    .then((response: any) => response.data)
}
