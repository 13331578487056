import { Routes } from '@angular/router';
import { authGuard } from '@guards/auth.guard';
import { RealEstateListPage } from './list/list.component';
import { RealEstateCreatePage } from './create/create.component';
import { RealEstateUpdatePage } from './update/update.component';
import { hasRolePermissionsGuard } from '@guards/has-role-permissions.guard';

let moduleName = 'realestate';

export const routes: Routes = [
  {
    path: moduleName,
    component: RealEstateListPage,
    title: 'Lista de propiedades',
    canActivate: [authGuard, hasRolePermissionsGuard(null, ['read_realestate'])],
  },
  {
    path: `${moduleName}/create`,
    component: RealEstateCreatePage,
    title: 'Crear propiedad',
    canActivate: [authGuard, hasRolePermissionsGuard(null, ['create_realestate'])],
  },
  {
    path: `${moduleName}/details/:slug`,
    component: RealEstateUpdatePage,
    title: 'Actualizar propiedad',
    canActivate: [authGuard, hasRolePermissionsGuard(null, ['read_realestate', 'update_realestate'])],
  },
];
