<backbone>
  <section header-page="topbar" class="d-flex h-100 position-relative">
    <div class="w-50 d-flex justify-content-start align-items-center">
      <p class="m-0 fw-medium">Galería: {{this.titlePage}}.</p>
    </div>

    <div class="w-50 d-flex justify-content-end align-items-center gap-3">
      <breadcrumb [links]="[{title: 'Dashboard'}, {title: 'Galería', link: '/gallery'}, {title: this.titlePage}]" />

      @if (this.gallery !== null) {
      <p-button size="small" icon="pi pi-save" label="Guardar" [loading]="isSaving" (onClick)="save()"
        [disabled]="!form.valid"></p-button>
      <p-button *showForPermissions="['delete_galleries']" size="small" icon="pi pi-trash" severity="danger"
        [loading]="isDeleting" (onClick)="delete($event)"></p-button>
      }
    </div>
  </section>

  <section wrapper>
    <form [formGroup]="form" [ngClass]="{'opacity-50 pointer-events user-select-none': this.isSaving === true}">
      <div class="card page-section rounded-2 shadow-sm">
        <div class="card-header align-items-center d-flex rounded-top-2">
          <h6 class="card-title flex-grow-1 m-0">Información principal de la galería.</h6>
        </div>
        <div class="card-body">
          <div class="mb-3 d-flex flex-column gap-2">
            <input formControlName="name" placeholder="Nombre" type="text" pInputText class="w-100 h-50px" />
            <small class="ps-2">Asígnale un nombre. <span class="text-danger">Requerido *</span></small>
          </div>
          <div class="d-flex flex-column gap-2">
            <input formControlName="description" placeholder="Descripción" type="text" pInputText
              class="w-100 h-50px" />
            <small class="ps-2">Puedes escribir una pequeña descripción, para identificar la galería. (Opcional)</small>
          </div>
        </div>
      </div>

      <div class="card page-section rounded-2 shadow-sm">
        <div class="card-header align-items-center d-flex rounded-top-2">
          <h6 class="card-title flex-grow-1 m-0">Galería.</h6>
          <small class="d-block">Formatos JPG, JPEG y PNG. Tamaño máximo 2mb.</small>
        </div>
        <div class="card-body">
          <div class="row">
            @for (item of images.controls; track item; let index = $index) {
            <div formArrayName="images" class="col-md-3 mb-4">
              <div class="card page-section rounded-2 shadow-sm">
                <div class="card-body p-0">
                  @if (getImageAsIndex(index)?.flag === true) {
                  <div class="d-flex flex-column gap-2 rounded-2">
                    <preview-upload-image imageSrc="{{getImageAsIndex(index)?.value}}">
                    </preview-upload-image>
                  </div>

                  <div class="end-0 position-absolute top-0 m-1 z-1">
                    <p-button icon="pi pi-trash" severity="danger" [rounded]="true" size="small"
                      (click)="removeImage(index)"></p-button>
                  </div>
                  }
                  @else {
                  <div class="d-flex flex-column gap-2 rounded-2"
                    [ngClass]="{'border border-danger border-4 text-bg-danger': getImageAsIndex(index)?.value?.size > this.maxSizesUpload}">
                    <preview-upload-image [image]="getImageAsIndex(index)?.value"
                      imageSrc="./assets/images/media-placeholder.png">
                      <input #input (change)="onInputFileChanged($event, index)" class="form-control h-50px" type="file"
                        style="line-height: 2.6;" accept="image/*">
                    </preview-upload-image>
                    @if (getImageAsIndex(index)?.value?.size > this.maxSizesUpload) {
                    <small class="text-white mb-2 px-2">El tamaño de la imagen no debe ser mayor a 2mb.</small>
                    }
                  </div>

                  <div class="end-0 position-absolute top-0 m-1 z-1">
                    <p-button icon="pi pi-trash" severity="danger" size="small" styleClass="rounded-2"
                      (click)="removeImage(index)"></p-button>
                  </div>
                  }
                </div>
              </div>
            </div>
            }

            <div class="col-md-3 mb-4">
              <div (click)="pushImage()"
                class="button-add-image align-items-center d-flex flex-column h-100 justify-content-center w-100 border-2 border rounded-2 py-5"
                style="border-style: dashed !important;" role="button">
                <div class="opacity-25">
                  <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" fill="currentColor"
                    class="bi bi-plus-lg" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                      d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </section>
</backbone>
