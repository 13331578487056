import { inject, Injectable } from '@angular/core';
import { HttpService } from '@utils/http.service';

@Injectable({
  providedIn: 'root'
})
export class BlogService {
  private http = inject(HttpService)

  public getList = async () => await this.http.get(`/v1/blog`)
    .then((response: any) => response.data)

  public getBlog = async (blog: string) => await this.http.get(`/v1/blog/${blog}?by=slug`)
    .then((response: any) => response.data)

  public create = async (body: any) => await this.http.post(`/v1/blog`, body)
    .then((response: any) => response)

  public save = async (blog: string, body: any) => await this.http.put(`/v1/blog/${blog}?by=slug`, body)
    .then((response: any) => response)

  public delete = async (blog: string) => await this.http.delete(`/v1/blog/${blog}?by=slug`)
    .then((response: any) => response.data)
}
