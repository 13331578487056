<backbone>
  <section header-page="topbar" class="d-flex h-100 position-relative">
    <div class="w-50 d-flex justify-content-start align-items-center">
      <p class="m-0 fw-medium">Lista de propiedades</p>
    </div>

    <div class="w-50 d-flex justify-content-end align-items-center gap-3">
      <breadcrumb [links]="[{title: 'Dashboard'}, {title: 'Lista de propiedades'}]" />

      <a *showForPermissions="['create_realestate']" class="btn btn-primary"
        routerLink="create"><small>Nuevo</small></a>
    </div>
  </section>

  <section wrapper>
    <div class="page-section card">
      <div class="card-body p-0">
        <p-table #dt1 [value]="propertiesList" dataKey="id" [rows]="25" [showCurrentPageReport]="true"
          [rowsPerPageOptions]="[25, 50, 100]" [paginator]="true"
          currentPageReportTemplate="Mostrando {first} de {last} de un total de {totalRecords} cartas de confirmación."
          [globalFilterFields]="['foil', 'title', 'state', 'municipality', 'zipcode', 'address', 'status', 'type']">
          <ng-template pTemplate="caption">
            <div class="d-flex align-items-stretch justify-content-between gap-2">
              <span class="p-input-icon-left ml-auto w-50">
                <i class="pi pi-search"></i>
                <input pInputText type="text" [(ngModel)]="searchValue" class="w-100 h-50px"
                  (input)="dt1.filterGlobal(searchValue, 'contains')"
                  placeholder="Buscar por título, fólio, estado, municipio, código postal, dirección, precio, estatus y tipo." />
              </span>
              <p-button label="Limpiar busqueda" [outlined]="true" icon="pi pi-filter-slash" (click)="clear(dt1)"
                styleClass="h-50px" />
            </div>
          </ng-template>

          <ng-template pTemplate="header">
            <tr>
              <th>Título</th>
              <th>Fólio</th>
              <th>Estado</th>
              <th>Municipio</th>
              <th class="text-center">CP</th>
              <th>Dirección</th>
              <th>Precio</th>
              <th>Estatus</th>
              <th>Tipo</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-property>
            <tr>
              <td class="text-nowrap">
                <a routerLink="details/{{property.slug}}"><small>{{property.title}}</small></a>
              </td>
              <td class="text-nowrap"><small>{{property.foil}}</small></td>
              <td class="text-nowrap"><small>{{property.state}}</small></td>
              <td class="text-nowrap"><small>{{property.municipality}}</small></td>
              <td class="text-nowrap" class="text-center"><small>{{property.zipcode}}</small></td>
              <td class="text-nowrap"><small>{{property.address}}</small></td>
              <td class="text-nowrap"><small>MX{{property.price | currency: 'MXN'}}</small></td>
              <td class="text-nowrap">
                <p-tag [value]="property.status" [severity]="getSeverity(property.status)"
                  styleClass="fw-normal small" />
              </td>
              <td class="text-nowrap"><small>{{property.type}}</small></td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </section>
</backbone>
