import { ApplicationConfig, LOCALE_ID } from '@angular/core';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideHttpClient } from '@angular/common/http';
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';
import { MessageService } from 'primeng/api';

import { registerLocaleData } from '@angular/common';
import localEsMx from '@angular/common/locales/es-MX';
registerLocaleData(localEsMx, 'es-MX');

import { routes } from './app.routes';
import { routes as home } from './pages/home/home.routes';
import { routes as login } from './pages/login/login.routes';
import { routes as lockscreen } from './pages/lockscreen/lockscreen.routes';
import { routes as realEstate } from './pages/realestate/realestate.routes';
import { routes as user } from './pages/user/user.routes';
import { routes as blog } from './pages/blog/blog.routes';
import { routes as gallery } from './pages/gallery/gallery.routes';
import { routes as pages } from './pages/dynamic-pages/dynamic-pages.routes';

export const appConfig: ApplicationConfig = {
  providers: [
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService,
    MessageService,

    { provide: LOCALE_ID, useValue: "es-MX" },
    provideAnimations(),
    provideHttpClient(),

    provideRouter(login, withComponentInputBinding()),
    provideRouter(lockscreen, withComponentInputBinding()),
    provideRouter(home, withComponentInputBinding()),
    provideRouter(realEstate, withComponentInputBinding()),
    provideRouter(user, withComponentInputBinding()),
    provideRouter(blog, withComponentInputBinding()),
    provideRouter(gallery, withComponentInputBinding()),
    provideRouter(pages, withComponentInputBinding()),

    provideRouter(routes, withComponentInputBinding())
  ]
};
