import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BackboneComponent } from '@components/backbone/backbone.component';
import { BreadcrumbComponent } from '@components/breadcrumb/breadcrumb.component';
import { BlogService } from '@services/blog.service';
import { ToastService } from '@utils/toast.service';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { ChipsModule } from 'primeng/chips';
import { PreviewUploadImageComponent } from '@components/preview-upload-image/preview-upload-image.component';
import { requiredFileSize, requiredFileType } from '@utils/validators';
import { EditorComponent, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';

@Component({
  selector: 'app-blog-create',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    BackboneComponent,
    BreadcrumbComponent,
    ReactiveFormsModule,
    InputTextModule,
    DropdownModule,
    ButtonModule,
    ChipsModule,
    PreviewUploadImageComponent,
    EditorComponent
  ],
  providers: [
    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' }
  ],
  templateUrl: './create.component.html',
  styleUrl: './create.component.scss'
})
export class BlogCreatePage {
  private formBuilder = inject(FormBuilder);
  private blogService = inject(BlogService);
  private toastService = inject(ToastService);
  private router = inject(Router);

  public isLoading = false;
  public isSaving = false;
  public separatorExp: RegExp = /,| /;
  public maxSizesUpload = 2000000;
  public tinymceInit: EditorComponent['init'] = {
    plugins: 'lists link image media table code wordcount emoticons preview searchreplace',
    toolbar: 'bold italic underline strikethrough | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | image media link | table | emoticons | searchreplace preview | code',
    menubar: false,
    license_key: 'gpl',
  };

  public formBlog = this.formBuilder.group({
    title: [null, Validators.required],
    description: [null, Validators.required],
    hero: [null, [Validators.required, requiredFileType(['png', 'jpg', 'jpeg']), requiredFileSize(this.maxSizesUpload)]],
    content: [null],
    tags: [null, Validators.required],
  });

  get title() { return this.formBlog.get('title') }
  set title(value: any) { this.title.setValue(value) }
  get description() { return this.formBlog.get('description') }
  set description(value: any) { this.description.setValue(value) }
  get hero() { return this.formBlog.get('hero') }
  set hero(value: any) { this.hero.setValue(value) }
  get content() { return this.formBlog.get('content') }
  set content(value: any) { this.content.setValue(value) }
  get tags() { return this.formBlog.get('tags') }
  set tags(value: any) { this.tags.setValue(value) }

  public onHeroChanged = (event: any) => {
    if (event.target.files && event.target.files.length) {
      this.hero = event.target.files[0];
    }
  }

  save = async () => {
    this.isSaving = true;

    const myFormData = new FormData();

    myFormData.append('title', this.title.value)
    myFormData.append('description', this.description.value)
    myFormData.append('hero', this.hero.value)
    myFormData.append('content', this.content.value)

    myFormData.append('tags', this.tags.value)

    await this.blogService.create(myFormData)
      .then(() => {
        this.toastService.success('Entrada al blog creada correctamente.')
        this.router.navigate(['/blog'])
      })
      .catch(() => this.toastService.error('Error al crear la entrada al blog.'))

    this.isSaving = false;
  }
}
