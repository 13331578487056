import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { BackboneComponent } from '@components/backbone/backbone.component';
import { BreadcrumbComponent } from '@components/breadcrumb/breadcrumb.component';
import { ShowForPermissionsDirective } from '@directives/show-for-permissions.directive';
import { BlogService } from '@services/blog.service';
import { ConfirmDialogService } from '@utils/confirm-dialog.service';
import { ToastService } from '@utils/toast.service';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { ChipsModule } from 'primeng/chips';
import { EditorComponent, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { PreviewUploadImageComponent } from '@components/preview-upload-image/preview-upload-image.component';
import { requiredFileSize, requiredFileType } from '@utils/validators';

@Component({
  selector: 'app-blog-update',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    BackboneComponent,
    BreadcrumbComponent,
    ReactiveFormsModule,
    ShowForPermissionsDirective,
    InputTextModule,
    ChipsModule,
    ButtonModule,
    EditorComponent,
    PreviewUploadImageComponent
  ],
  providers: [
    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' }
  ],
  templateUrl: './update.component.html',
  styleUrl: './update.component.scss'
})
export class BlogUpdatePage {
  private formBuilder = inject(FormBuilder)
  private activatedRoute = inject(ActivatedRoute)
  private titleService = inject(Title)
  private toastService = inject(ToastService)
  private confirmDialogService = inject(ConfirmDialogService)
  private router = inject(Router)
  private blogService = inject(BlogService)

  public isLoading = false;
  public isSaving = false;
  public isDeleting = false;
  public separatorExp: RegExp = /,| /;
  public maxSizesUpload = 2000000;
  public tinymceInit: EditorComponent['init'] = {
    plugins: 'lists link image media table code wordcount emoticons preview searchreplace',
    toolbar: 'bold italic underline strikethrough | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | image media link | table | emoticons | searchreplace preview | code',
    menubar: false,
    license_key: 'gpl',
  };

  public blog: any

  public formBlog = this.formBuilder.group({
    title: [null, Validators.required],
    description: [null, Validators.required],
    hero: [null, [requiredFileType(['png', 'jpg', 'jpeg']), requiredFileSize(this.maxSizesUpload)]],
    content: [null],
    tags: [null, Validators.required],
  });

  get title() { return this.formBlog.get('title') }
  set title(value: any) { this.title.setValue(value) }
  get description() { return this.formBlog.get('description') }
  set description(value: any) { this.description.setValue(value) }
  get hero() { return this.formBlog.get('hero') }
  set hero(value: any) { this.hero.setValue(value) }
  get content() { return this.formBlog.get('content') }
  set content(value: any) { this.content.setValue(value) }
  get tags() { return this.formBlog.get('tags') }
  set tags(value: any) { this.tags.setValue(value) }

  public onHeroChanged = (event: any) => {
    if (event.target.files && event.target.files.length) {
      this.hero = event.target.files[0];
    }
  }

  ngOnInit() {
    this.isLoading = true;

    this.activatedRoute.params.subscribe(async routeParams => {
      await this.blogService.getBlog(routeParams['slug'])
        .then((response: any) => this.blog = response.content)

      if (this.blog.length === 0) {
        this.router.navigate(['/blog'])
        this.toastService.error('La entrada al blog, no existe.')
      } else {
        this.titleService.setTitle('Editando la entrada al blog: ' + this.blog.title + ' - ' + this.titleService.getTitle());

        this.title.setValue(this.blog.title);
        this.description.setValue(this.blog.description);
        this.content.setValue(this.blog.content);
        this.tags.setValue(this.blog.tags.split(','));

        this.isLoading = false;
      }
    });
  }

  save = async () => {
    this.isSaving = true;

    const myFormData = new FormData();

    myFormData.append('title', this.title.value)
    myFormData.append('description', this.description.value)
    myFormData.append('content', this.content.value)
    myFormData.append('tags', this.tags.value)
    myFormData.append('hero', this.hero.value)

    await this.blogService.save(this.blog.slug, myFormData)
      .then((response) => this.toastService.success('Entrada al blog, guardada exitosamente.'))
      .catch((error: any) => this.toastService.error(error.error.data.message))

    this.isSaving = false;
  }

  delete = (event: Event) => {
    this.isDeleting = true;

    this.confirmDialogService.dialog('¿Está seguro de eliminar esta entrada al blog?', 'Continuar', 'Cancelar')
      .then(() => {
        this.isLoading = true

        this.blogService.delete(this.blog.slug)
          .then(() => {
            this.toastService.success('Entrada al blog eliminada exitosamente.')
            this.router.navigate(['/blog'])
          })
          .catch((error: any) => {
            this.toastService.error(error.error.data.message)
            this.isDeleting = false
            this.isLoading = false
          })
      })
      .catch(() => this.isDeleting = false)
  }
}
