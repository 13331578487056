import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormArray, FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { BackboneComponent } from '@components/backbone/backbone.component';
import { BreadcrumbComponent } from '@components/breadcrumb/breadcrumb.component';
import { PreviewUploadImageComponent } from '@components/preview-upload-image/preview-upload-image.component';
import { ShowForPermissionsDirective } from '@directives/show-for-permissions.directive';
import { GalleryService } from '@services/gallery.service';
import { ConfirmDialogService } from '@utils/confirm-dialog.service';
import { ToastService } from '@utils/toast.service';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { requiredFileSize, requiredFileType } from '@utils/validators';

@Component({
  selector: 'app-gallery-update',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    BackboneComponent,
    BreadcrumbComponent,
    ReactiveFormsModule,
    ShowForPermissionsDirective,
    ButtonModule,
    InputTextModule,
    PreviewUploadImageComponent
  ],
  templateUrl: './update.component.html',
  styleUrl: './update.component.scss'
})
export class GalleryUpdatePage {
  private formBuilder = inject(FormBuilder);
  private activatedRoute = inject(ActivatedRoute);
  private galleryService = inject(GalleryService);
  private toastService = inject(ToastService);
  private router = inject(Router);
  private titleService = inject(Title);
  private confirmDialogService = inject(ConfirmDialogService);

  public isLoading = false;
  public isSaving = false;
  public isDeleting = false;
  public maxSizesUpload = 2000000;

  public titlePage = '';
  public gallery: any = []

  public form = this.formBuilder.group({
    name: [null, [Validators.required]],
    description: [null],
    images: this.formBuilder.array([])
  });

  get name() { return this.form.get('name') }
  set name(value: any) { this.name.setValue(value) }
  get description() { return this.form.get('description') }
  set description(value: any) { this.description.setValue(value) }

  get images(): FormArray { return this.form.controls["images"] as FormArray; }
  getImageAsIndex = (index: number): any => {
    if (typeof this.images.controls[index].get('file')?.value === 'string') {
      return {
        value: this.images.controls[index].get('file')?.value,
        flag: true
      }
    } else {
      return this.images.controls[index].get('file')
    }
  }

  pushImage = () => this.images.push(this.formBuilder.group({ file: [null, [requiredFileType(['png', 'jpg', 'jpeg']), requiredFileSize(this.maxSizesUpload)]] }))
  removeImage = (index: number) => {
    this.images.removeAt(index);

    if (this.images.value <= 0) {
      this.pushImage()
    }
  }

  ngOnInit() {
    this.isLoading = true;

    this.activatedRoute.params.subscribe(async routeParams => {
      await this.galleryService.getGallery(routeParams['id'])
        .then((response: any) => this.gallery = response.content)

      if (this.gallery.length === 0) {
        this.router.navigate(['/gallery'])
        this.toastService.error('La galería no existe.')
      } else {
        this.titlePage = this.gallery.name;
        this.titleService.setTitle('Viendo la galería: ' + this.titlePage + ' - ' + this.titleService.getTitle());

        this.name = this.gallery.name;
        this.description = this.gallery.description;

        if (this.gallery.images.length > 0) {
          for (const [index, item] of this.gallery.images.entries()) {
            this.images.push(this.formBuilder.group({ file: [item.uri] }))
          }
        } else {
          this.pushImage()
        }

        this.isLoading = false;
      }
    });
  }

  onInputFileChanged = (event: any, index: number) => {
    if (event.target.files && event.target.files.length) {
      this.images.controls[index].get('file')?.setValue(event.target.files[0]);
    }
  }

  save = async () => {
    this.isSaving = true;

    const myFormData = new FormData();

    myFormData.append('name', this.name.value)
    myFormData.append('description', this.description.value)

    for (const [index, item] of this.images.value.entries()) {
      myFormData.append(`image_${index}`, item.file)
    }

    await this.galleryService.update(this.gallery.galleryId, myFormData)
      .then(() => this.toastService.success('Galería actualizada.'))
      .catch(() => this.toastService.error('Error al actualizar la galería.'))

    this.isSaving = false;
  }

  delete = (event: Event) => {
    this.isDeleting = true;

    this.confirmDialogService.dialog('¿Está seguro de eliminar esta galería?', 'Continuar', 'Cancelar')
      .then(() => {
        this.isLoading = true;

        this.galleryService.delete(this.gallery.galleryId)
          .then(() => {
            this.toastService.success('Galería eliminada exitosamente.')
            this.router.navigate(['/gallery'])
          })
          .catch((error: any) => {
            this.toastService.error(error.error.data.message)
            this.isDeleting = false
            this.isLoading = false
          })
      })
      .catch(() => this.isDeleting = false)
  }
}
