<backbone>
  <section header-page="topbar" class="d-flex h-100 position-relative">
    <div class="w-50 d-flex justify-content-start align-items-center">
      <p class="m-0 fw-medium">Viendo la propiedad: {{this.data?.title}}</p>
    </div>

    <div class="w-50 d-flex justify-content-end align-items-center gap-3">
      <breadcrumb
        [links]="[{title: 'Dashboard'}, {title: 'Propiedades', link: '/realestate'}, {title: 'Actualizar'}]" />

      @if (this.data !== null) {
      <p-button size="small" icon="pi pi-save" label="Actualizar" [loading]="isSaving" (onClick)="save()"
        [disabled]="!form.valid"></p-button>
      <p-button *showForPermissions="['delete_realestate']" size="small" icon="pi pi-trash" severity="danger"
        [loading]="isDeleting" (onClick)="delete($event)"></p-button>
      }
    </div>
  </section>

  <section wrapper>
    <form [formGroup]="form" [ngClass]="{'opacity-50 pointer-events-none user-select-none': this.isSaving === true}">
      <div class="row">
        <div class="col-8">
          <div class="card page-section rounded-2 shadow-sm">
            <div class="card-header align-items-center d-flex rounded-top-2">
              <h6 class="card-title flex-grow-1 m-0">Información principal de la propiedad.</h6>
            </div>
            <div class="card-body">
              <div class="mb-3 d-flex flex-column gap-2">
                <input formControlName="title" placeholder="Nombre de la propiedad" type="text" pInputText
                  class="w-100 h-50px" />
                <small class="ps-2">Escribe un nombre para la propiedad. <span class="text-danger">Requerido
                    *</span></small>
              </div>
              <div class="mb-3 d-flex flex-column gap-2">
                <input formControlName="foil" placeholder="Folio de la propiedad" type="text" pInputText
                  class="w-100 h-50px" />
                <small class="ps-2">Escribe un folio para la propiedad.</small>
              </div>
              <div class="d-flex gap-2">
                <div class="d-flex flex-column gap-2 w-50">
                  <p-dropdown formControlName="type" [options]="typeProperties" optionLabel="label" optionValue="value"
                    showClear="false" [filter]="true" filterBy="label" placeholder="Tipo de propiedad"
                    emptyMessage="No hay resultados" emptyFilterMessage="No se encontraron resultados"
                    styleClass="w-100 h-50px">
                    <ng-template pTemplate="selectedItem" let-selectedOption>
                      <div>{{ selectedOption.label }}</div>
                    </ng-template>
                    <ng-template let-item pTemplate="item">
                      <div>{{ item.label }}</div>
                    </ng-template>
                  </p-dropdown>
                  <small class="ps-2">Selecciona un tipo de propiedad. <span class="text-danger">Requerido
                      *</span></small>
                </div>
                <div class="d-flex flex-column gap-2 w-50">
                  <p-dropdown formControlName="status" [options]="statusProperties" optionLabel="label"
                    optionValue="value" showClear="false" [filter]="true" filterBy="label" placeholder="Estatus"
                    emptyMessage="No hay resultados" emptyFilterMessage="No se encontraron resultados"
                    styleClass="w-100 h-50px">
                    <ng-template pTemplate="selectedItem" let-selectedOption>
                      <div>{{ selectedOption.label }}</div>
                    </ng-template>
                    <ng-template let-item pTemplate="item">
                      <div>{{ item.label }}</div>
                    </ng-template>
                  </p-dropdown>
                  <small class="ps-2">Selecciona un estatus para la propiedad. <span class="text-danger">Requerido
                      *</span></small>
                </div>
              </div>
            </div>
          </div>

          <div class="card page-section rounded-2 shadow-sm">
            <div class="card-header align-items-center d-flex rounded-top-2">
              <h6 class="card-title flex-grow-1 m-0">Ubicación</h6>
            </div>
            <div class="card-body">
              <div class="mb-3 d-flex gap-2">
                <div class="d-flex flex-column gap-2 w-50">
                  <p-dropdown formControlName="state" [options]="states" optionLabel="label" optionValue="value"
                    showClear="true" [filter]="true" filterBy="label" placeholder="Estado"
                    emptyMessage="No hay resultados" emptyFilterMessage="No se encontraron resultados"
                    styleClass="w-100 h-50px" (onChange)="changeState()">
                    <ng-template pTemplate="selectedItem" let-selectedOption>
                      <div>{{ selectedOption.label }}</div>
                    </ng-template>
                    <ng-template let-item pTemplate="item">
                      <div>{{ item.label }}</div>
                    </ng-template>
                  </p-dropdown>
                  <small class="ps-2">Estado. <span class="text-danger">Requerido *</span></small>
                </div>
                <div class="d-flex flex-column gap-2 w-50">
                  <p-dropdown formControlName="municipality" [options]="municipalities" optionLabel="label"
                    optionValue="value" showClear="true" [filter]="true" filterBy="label" placeholder="Municipio"
                    emptyMessage="No hay resultados" emptyFilterMessage="No se encontraron resultados"
                    styleClass="w-100 h-50px">
                    <ng-template pTemplate="selectedItem" let-selectedOption>
                      <div>{{ selectedOption.label }}</div>
                    </ng-template>
                    <ng-template let-item pTemplate="item">
                      <div>{{ item.label }}</div>
                    </ng-template>
                  </p-dropdown>
                  <small class="ps-2">Municipio. <span class="text-danger">Requerido *</span></small>
                </div>
              </div>
              <div class="mb-3 d-flex gap-2">
                <div class="d-flex flex-column gap-2 w-50">
                  <input formControlName="address" placeholder="Dirección" type="text" pInputText
                    class="w-100 h-50px" />
                  <small class="ps-2">Dirección. <span class="text-danger">Requerido *</span></small>
                </div>
                <div class="d-flex flex-column gap-2 w-25">
                  <input formControlName="city" placeholder="Ciudad" type="text" pInputText class="w-100 h-50px"
                    pKeyFilter="alpha" />
                  <small class="ps-2">Ciudad. <span class="text-danger">Requerido *</span></small>
                </div>
                <div class="d-flex flex-column gap-2 w-25">
                  <input formControlName="zipcode" placeholder="Código Postal" type="text" pInputText
                    class="w-100 h-50px" pKeyFilter="num" />
                  <small class="ps-2">Código Postal. <span class="text-danger">Requerido *</span></small>
                </div>
              </div>
            </div>
          </div>

          <div class="card page-section rounded-2 shadow-sm">
            <div class="card-header align-items-center d-flex rounded-top-2">
              <h6 class="card-title flex-grow-1 m-0">Información de la propiedad</h6>
            </div>
            <div class="card-body">
              <div class="mb-3 d-flex gap-4">
                <div class="d-flex flex-column gap-2 w-50">
                  <p-inputNumber formControlName="area" [step]="1" [min]="0" [maxFractionDigits]="2"
                    [showButtons]="true" buttonLayout="horizontal" decrementButtonClass="p-button-primary me-1"
                    decrementButtonIcon="pi pi-minus" incrementButtonClass="p-button-primary ms-1"
                    incrementButtonIcon="pi pi-plus" styleClass="w-100" inputStyleClass="text-center"></p-inputNumber>
                  <small class="text-center">Metros cuadrados de terreno. <span class="text-danger">Requerido
                      *</span></small>
                </div>
                <div class="d-flex flex-column gap-2 w-50">
                  <p-inputNumber formControlName="room" [step]="1" [min]="0" [maxFractionDigits]="2"
                    [showButtons]="true" buttonLayout="horizontal" decrementButtonClass="p-button-primary me-1"
                    decrementButtonIcon="pi pi-minus" incrementButtonClass="p-button-primary ms-1"
                    incrementButtonIcon="pi pi-plus" styleClass="w-100" inputStyleClass="text-center"></p-inputNumber>
                  <small class="text-center">Número de recamaras. <span class="text-danger">Requerido *</span></small>
                </div>
              </div>
              <div class="mb-3 d-flex gap-4">
                <div class="d-flex flex-column gap-2 w-50">
                  <p-inputNumber formControlName="bath" [step]="1" [min]="0" [maxFractionDigits]="2"
                    [showButtons]="true" buttonLayout="horizontal" decrementButtonClass="p-button-primary me-1"
                    decrementButtonIcon="pi pi-minus" incrementButtonClass="p-button-primary ms-1"
                    incrementButtonIcon="pi pi-plus" styleClass="w-100" inputStyleClass="text-center"></p-inputNumber>
                  <small class="text-center">Número de baños. <span class="text-danger">Requerido *</span></small>
                </div>
                <div class="d-flex flex-column gap-2 w-50">
                  <p-inputNumber formControlName="floor" [step]="1" [min]="0" [maxFractionDigits]="2"
                    [showButtons]="true" buttonLayout="horizontal" decrementButtonClass="p-button-primary me-1"
                    decrementButtonIcon="pi pi-minus" incrementButtonClass="p-button-primary ms-1"
                    incrementButtonIcon="pi pi-plus" styleClass="w-100" inputStyleClass="text-center"></p-inputNumber>
                  <small class="text-center">Número de pisos. <span class="text-danger">Requerido *</span></small>
                </div>
              </div>
              <div class="d-flex gap-4">
                <div class="d-flex flex-column gap-2 w-25">
                  <p-dropdown formControlName="currency" [options]="currencies" optionLabel="label" optionValue="value"
                    showClear="false" [filter]="true" filterBy="label" placeholder="Moneda"
                    emptyMessage="No hay resultados" emptyFilterMessage="No se encontraron resultados"
                    styleClass="w-100 h-50px">
                    <ng-template pTemplate="selectedItem" let-selectedOption>
                      <div>{{ selectedOption.label }} ({{ selectedOption.value }})</div>
                    </ng-template>
                    <ng-template let-item pTemplate="item">
                      <div>{{ item.label }} ({{ item.value }})</div>
                    </ng-template>
                  </p-dropdown>
                  <small class="ps-2">Moneda.</small>
                </div>
                <div class="d-flex flex-column gap-2 w-25">
                  <p-inputNumber formControlName="price" mode="currency" [currency]="inputCurrency.value" locale="es-MX"
                    inputStyleClass="w-100 h-50px" styleClass=" w-100" placeholder="$0.00"></p-inputNumber>
                  <small class="ps-2">Precio. <span class="text-danger">Requerido *</span></small>
                </div>
              </div>
            </div>
          </div>

          <div class="card page-section rounded-2 shadow-sm">
            <div class="card-header align-items-center d-flex rounded-top-2">
              <h6 class="card-title flex-grow-1 m-0">Descripción detallada.</h6>
            </div>
            <div class="card-body p-0">
              <editor formControlName="description" [init]="tinymceInit" />
            </div>
          </div>

          <div class="card page-section rounded-2 shadow-sm">
            <div class="card-header align-items-center d-flex rounded-top-2">
              <h6 class="card-title flex-grow-1 m-0">Galería.</h6>
              <small class="d-block">Formatos JPG, JPEG y PNG. Tamaño máximo 2mb.</small>
            </div>
            <div class="card-body">
              <div class="row">
                @for (item of inputGallery.controls; track item; let index = $index) {
                <div formArrayName="gallery" class="col-md-3 mb-4">
                  <div class="card page-section rounded-2 shadow-sm">
                    <div class="card-body p-0">
                      @if (getImageAsIndex(index)?.flag === true) {
                      <div class="d-flex flex-column gap-2 rounded-2">
                        <preview-upload-image imageSrc="{{getImageAsIndex(index)?.value}}">
                        </preview-upload-image>
                      </div>

                      <div class="end-0 position-absolute top-0 m-1 z-1">
                        <p-button icon="pi pi-trash" severity="danger" [rounded]="true" size="small"
                          (click)="removeImage(index)"></p-button>
                      </div>
                      }
                      @else {
                      <div class="d-flex flex-column gap-2 rounded-2"
                        [ngClass]="{'border border-danger border-4 text-bg-danger': getImageAsIndex(index)?.value?.size > this.maxSizesUpload}">
                        <preview-upload-image [image]="getImageAsIndex(index)?.value"
                          imageSrc="./assets/images/media-placeholder.png">
                          <input #input (change)="onInputFileChanged($event, index)" class="form-control h-50px"
                            type="file" style="line-height: 2.6;" accept="image/*">
                        </preview-upload-image>
                        @if (getImageAsIndex(index)?.value?.size > this.maxSizesUpload) {
                        <small class="text-white mb-2 px-2">El tamaño de la imagen no debe ser mayor a 2mb.</small>
                        }
                      </div>

                      <div class="end-0 position-absolute top-0 m-1 z-1">
                        <p-button icon="pi pi-trash" severity="danger" size="small" styleClass="rounded-2"
                          (click)="removeImage(index)"></p-button>
                      </div>
                      }
                    </div>
                  </div>
                </div>
                }

                <div class="col-md-3 mb-4">
                  <div (click)="pushImage()"
                    class="button-add-image align-items-center d-flex flex-column h-100 justify-content-center w-100 border-2 border rounded-2 py-5"
                    style="border-style: dashed !important;" role="button">
                    <div class="opacity-25">
                      <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" fill="currentColor"
                        class="bi bi-plus-lg" viewBox="0 0 16 16">
                        <path fill-rule="evenodd"
                          d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2" />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-4">
          <div class="card page-section rounded-2 shadow-sm">
            <div class="card-header align-items-center d-flex rounded-top-2">
              <h6 class="card-title flex-grow-1 m-0">Imágen de portada.</h6>
            </div>
            <div class="card-body">
              <div class="d-flex flex-column gap-2">
                <preview-upload-image [image]="inputHero.value"
                  [imageSrc]="(this.data?.hero?.name !== null && this.data?.hero?.name !== '') ? this.data?.hero?.uri : './assets/images/media-placeholder.png'"
                  [ngClass]="{'border border-danger border-4 rounded-1': inputHero.value?.size > this.maxSizesUpload}">
                  <input #input (change)="onHeroChanged($event)" class="form-control h-50px" type="file"
                    style="line-height: 2.6;" accept="image/*">
                </preview-upload-image>
                @if (inputHero.value?.size > this.maxSizesUpload) {
                <small class="px-2 text-danger">El tamaño de la imagen no debe ser mayor a 2mb.</small>
                }
                <small class="ps-2 d-block">Formatos JPG, JPEG y PNG. Tamaño máximo 2mb. <span
                    class="text-danger">Requerido *</span></small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </section>
</backbone>
