import { inject, Injectable } from '@angular/core';
import { HttpService } from '@utils/http.service';

@Injectable({
  providedIn: 'root'
})
export class GalleryService {
  private http = inject(HttpService)

  public getList = async () => await this.http.get(`/v1/gallery`)
    .then((response: any) => response.data)

  public getGallery = async (id: string) => await this.http.get(`/v1/gallery/${id}`)
    .then((response: any) => response.data)

  public create = async (body: any) => await this.http.post(`/v1/gallery`, body)
    .then((response: any) => response)

  public update = async (id: string, body: any) => await this.http.put(`/v1/gallery/${id}`, body)
    .then((response: any) => response)

  public delete = async (id: string) => await this.http.delete(`/v1/gallery/${id}`)
    .then((response: any) => response.data)
}
